import React, { useEffect } from "react";
import { filter, find, first, get, includes, map, sortBy } from "lodash";
import UserAvatar from "../UserAvatar";
import TiersChart from "./tiers-chart";
import { getMemeberSinceDate } from "../../utils/user";
import { getStoreCreditBalanceFromUser } from "../../logic/user/getters/getStoreCreditBalanceFromUser";

import * as styles from "./index.module.scss";
import TiersList from "./tiers-list";
import Chip from "../Chip";
import { isTemplateEnabled } from "../../logic/templates/isTemplateEnabled";
import { HOMEPAGE_TEMPLATE_KEY } from "../../utils/constants";
import RewardPointsTracker from "./points-tracker";
import Banner from "./banner";

export default (props) => {
  const { T, business, user, businessAppConfiguration = {}, appStyles, pointBankCoupons, app } = props;
  if (user.loggedIn) {
    useEffect(() => {
      props.loadLoyaltyProfile();
    });
  }

  const points = get(
    user,
    "loyaltyProfile.data.rewardPointBalance.balance",
    "-",
  );
  const visitCount = get(user, "loyaltyProfile.data.visitCount", "-");

  const tiers = filter(
    get(user, "loyaltyProfile.data.accumulativeCouponPoilcyRemainders"),
    (policy) => policy.type === "TIERS",
  );

  const nextTierData = first(
    sortBy(filter(tiers, (policy) => policy.remainder > 0), "remainder"),
  );

  const currentTierId = get(find(tiers, "isInGroup"), "groupId");

  const groupIds = get(user, "loyaltyProfile.data.groupIds");

  const groups = filter(business.groups, ({ id }) => includes(groupIds, id));

  const renderTiersListComponent = isTemplateEnabled(appStyles, HOMEPAGE_TEMPLATE_KEY.loyalty) && user.loggedIn;
  const { homepageShowTiersList } = appStyles;
  const storeCreditBalance = getStoreCreditBalanceFromUser(user);

  return (
    <div className={styles.LoyaltyProfileContainer}>
      <div className={styles.UserDetails}>
        <UserAvatar
          size={50}
          name={get(user, "userDetails.data.name", "")}
          avatarUrl={get(user, "userDetails.data.avatarImage.url")}
          appStyles={appStyles}
          style={appStyles.rtl ? { marginLeft: 16 } : { marginRight: 16 }}
        />
        <div>
          <div>{get(user, "userDetails.data.name", "")}</div>
          <div className={styles.MemberSince}>
            <small>{getMemeberSinceDate(user, T)}</small>
          </div>
        </div>
      </div>
      {renderTiersListComponent && homepageShowTiersList ? (
        <TiersList
          T={T}
          currencySymbol={business.currencySymbol}
          currentTierId={currentTierId}
          nextTierData={nextTierData}
          groups={business.groups}
          tiers={tiers}
          appStyles={appStyles}
        />
      ) : (
        <>
          <TiersChart
            T={T}
            currencySymbol={business.currencySymbol}
            className={styles.TiersChart}
            currentTierId={currentTierId}
            nextTierData={nextTierData}
            groups={business.groups}
            appStyles={appStyles}
          />
          <div className={styles.UserGroups}>
            {map(groups, (group) => (
              <Chip
                label={group.name}
                style={{
                  backgroundColor: group.badgeBackgroundColor,
                  color: group.badgeTextColor,
                }}
              />
            ))}
          </div>
        </>
      )}
      {!get(appStyles, "rewardPointsTracker.enableRewardPointsTracker", false) && 
      <div className={styles.UserStats}>
        {!appStyles.hideVisitCount && (
            <div className={styles.StatRecord}>
              <div className={styles.StatValue}>{visitCount}</div>
              <div className={styles.StatLabel}>{T("Visits")}</div>
            </div>
          )}
          {businessAppConfiguration.hasPointBank && (
            <>
              {!appStyles.hideVisitCount && (<div className={styles.Divider} />)}
              <div className={styles.StatRecord}>
                <div className={styles.StatValue}>{(points && (points.toFixed)) && points?.toFixed(0)}</div>
                <div className={styles.StatLabel}>{T("Points")}</div>
                {T("{pointsRedemptionDescription}") !==
                  "{pointsRedemptionDescription}" && (
                  <div className={styles.MemberSince}>
                    {T("{pointsRedemptionDescription}")}
                  </div>
                )}
              </div>
            </>
          )}
          {storeCreditBalance > 0.01 && (
            <>
              <div className={styles.Divider} />
              <div className={styles.StatRecord}>
                <div className={styles.StatValue}>{`${business.currencySymbol}${storeCreditBalance}`}</div>
                <div className={styles.StatLabel}>{T("Store Credit")}</div>
              </div>
            </>
          )}
      </div>}
      {businessAppConfiguration.hasPointBank && get(appStyles, "rewardPointsTracker.enableRewardPointsTracker", false) &&
        <RewardPointsTracker 
          pointBankCoupons={pointBankCoupons}
          user={user}
          appStyles={appStyles}
          isMobile={app.isMobile}
          T={T}
        />
      }
      {get(appStyles, "rewardBanner.enableRewardBanner", false) &&
        <Banner 
          storeCreditBalance={storeCreditBalance}
          appStyles={appStyles}
          isMobile={app.isMobile}
          T={T}
          business={business}
        />
      }
    </div>
  );
};
