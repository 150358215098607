import { get, reduce, sortBy, groupBy, toPairs, sumBy, map } from "lodash";

export const accumulatePaymentDetails = (paymentDetails) => {
  const perDayPaymentDetails = map(
    toPairs(
      groupBy(
        paymentDetails,
        ({ date }) => new Date(new Date(date).toDateString())
      )
    ),
    ([date, payments]) => ({ date, amount: sumBy(payments, "amount") })
  );
  const accumulatePayments = reduce(
    sortBy(perDayPaymentDetails, "date"),
    (acc, payment, index) => {
      return [
        ...acc,
        {
          date: payment.date,
          dateLabel: new Date(payment.date).getDate(),
          amount: payment.amount + get(acc[index - 1], "amount", 0),
        },
      ];
    },
    []
  );
  return accumulatePayments;
};
